import React, { Component } from "react";
import merge from 'lodash/merge'
import axios from 'axios';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// CSS Modules, react-datepicker-cssmodules.css
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import ca from 'date-fns/locale/ca';
import SearchAddress from './SearchAddress.jsx'

registerLocale('ca', ca)

class RegalaForm extends Component{
    constructor(){
        console.log("regala_code_params", regala_code_params);
        super();
        this.state = {
            dia: 0,
            mes: 0,
            any: 0,
            signup_server_url: regala_code_params.signup_server,
            max_date: "",
            step: regala_code_params.initial_step,
            next_step: 0,
            payment_type: regala_code_params.payment_type,
            token: regala_code_params.token,
            lead_token: "",
            regala_code: regala_code_params.code,
            form_enabled: true,
            name: "",
            last_name: "",
            email: "",
            phone:"",
            address1: "",
            address2: "",
            birthdate: "",
            genere: "",
            dni: "",
            province: "",
            route: "",
            country_code: "",
            formatted_address: "",
            legal_conditions: false,
            street_info_line1: "",
            street_info_line2: "",
            street_number: "",
            zip_code: "",

            validate_elements: { 
                dia: true,
                mes: true,
                any: true,
                regala_code: false,
                name: false,
                last_name: false,
                email: false,
                phone: false,
                birthdate: false,    
                legal_conditions: false,
                address1: false,
                address2: false,
                genere: false,
                dni: false,
                street_info_line1: false,
                street_info_line2: false

            },
            valid_elements: {
                dia: false,
                mes: false,
                any: false,
                regala_code: false,
                name: false,
                last_name: false,
                email: false,
                phone: false,
                birhtdate: true,
                address1: false,
                address2: false,
                legal_conditions: false,
                genere: true,
                dni: false,
                street_info_line1: false,
                street_info_line2: false
            },
            valid_form: false,
            validate_form: false,
            
        }
        console.log("state:", this.state);
        this.saveCode = this.saveCode.bind(this);
        this.saveLead = this.saveLead.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.elementClassName = this.elementClassName.bind(this);
        this.saveAll = this.saveAll.bind(this);
        this.validateDNI = this.validateDNI.bind(this);
        this.setAddress = this.setAddress.bind(this);
        this.birthdateChange = this.birthdateChange.bind(this);

    }
    birthdateChange(e){
        
        let birthdate;
        let newState = merge({}, this.state);
        switch (e.target.name){
            case "dia":
                console.log("Dia:", e.target.value, e.target.name)
                birthdate = new Date(this.state.any, this.state.mes-1, e.target.value);
                newState["dia"] = birthdate.getDate();
                break;
            case "mes":
                birthdate = new Date(this.state.any, e.target.value-1, this.state.dia);
                newState["mes"] = birthdate.getMonth()+1;
                break;
            case "any":
                birthdate = new Date(e.target.value, this.state.mes-1, this.state.dia);
                newState["any"] = birthdate.getFullYear();
                break;
        }

        if(parseInt(e.target.value)>0){
            newState["valid_elements"][e.target.name]=true;
        } else {
            newState["valid_elements"][e.target.name]=false;
        }
        console.log("Birthdate:", birthdate, "-", this.state.dia, this.state.mes,"...");
        newState["birthdate"]=birthdate;
        console.log("NewState", newState)
        this.setState(newState);
    }

    validateDNI(value){
        let dni = value
        let numero, letr, letra;
        let expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;

        if(expresion_regular_dni.test(dni) === true){
            numero = dni.substr(0,dni.length-1);
            numero = numero.replace('X', 0);
            numero = numero.replace('Y', 1);
            numero = numero.replace('Z', 2);
            letr = dni.substr(dni.length-1, 1);
            numero = numero % 23;
            letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
            letra = letra.substring(numero, numero+1);
            if (letra !== letr) {
                return false;
            } else {
                return true;
            }
        }else{
            return false;
        }
    }

    setBirthdate(date){
        console.log(date);
        let newState = merge({}, this.state);
        newState["validate_elements"]["birthdate"]=true;
        newState["valid_elements"]["birthdate"]=true;
        newState["birthdate"]=date;
        this.setState(newState);
    }

    saveCode(){
        console.log("Code:", this.state["valid_elements"]["regala_code"]);
        if (!this.state["valid_elements"]["regala_code"]){
            return
        }
        this.setState({step: 10, next_step: 2});

        return axios.post(
            this.state.signup_server_url + "/rgl_form/validatecode",
            {
                code: this.state.regala_code
            },
            { headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            }
        }).then((response)=>{
                console.log("Response", response);
                this.setState(
                    {
                        payment_type: response.data.payment_type, 
                        token: response.data.token,
                        step: 1
                    }
                
                );
            }).catch((error) => {
                console.log("Error",error.response.status);
                if(error.response.status=="404"){
                    this.setState({step: 30});
                } else {
                    this.setState({step: 20});
                }
                
            });
    }
    saveLead(){
        // sends lead info to signupServer
        
        // check all fields in form are valid, and send info to server
        let elements_to_validate = ["name", "last_name", "email", "phone", "legal_conditions"]
        let form_is_valid = true;
        for(let item in elements_to_validate){
            if (!this.state.valid_elements[elements_to_validate[item]]){
                // console.log("Item valid:",this.state.valid_elements, elements_to_validate[item]);
                form_is_valid = false;
            }
        }
        if(!form_is_valid){
            return;
        } else {
            console.log("Save Lead")
            this.setState({step: 10});
            return axios.post(
                this.state.signup_server_url+"/rgl_form/create_lead",
                {
                    token: this.state.token,
                    name: this.state.name,
                    last_name: this.state.last_name,
                    email: this.state.email,
                    phone: this.state.phone,
                    legal_conditions: this.state.legal_conditions
                },
                { headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                }
            }).then((response)=>{
                    console.log("Response save lead", response);
                    this.setState({lead_token: response.data.lead_token, step: 2});
                }).catch((error) => {
                    console.log("Error",error);
                    this.setState({step: 20});
                });
        }
    }

    saveAll(){
        let form_is_valid = true;
        form_is_valid = this.state.formatted_address === "" ? false : true
        form_is_valid = this.state.genere === "-" ? false : true
        form_is_valid = this.validateDNI(this.state.dni) ? true : false
        

        if(!form_is_valid){
            alert("Si us plau, omple els camps obligatoris");
            return false;
        } else {
            let signup_birthdate = this.state.birthdate
            let payload_birthdate = signup_birthdate.getFullYear() + "-" + (signup_birthdate.getMonth() + 1) +"-"+signup_birthdate.getDate()
            // prepare update lead
            console.log(this.state, "Update lead!");
            this.setState({step: 10});
            let payload = {
                lead_token: this.state.lead_token,
                token: this.state.token,
                name: this.state.name,
                last_name: this.state.last_name,
                email: this.state.email,
                phone: this.state.phone,
                birth_date: payload_birthdate, //1970-1-9
                dni: this.state.dni,
                gender: this.state.genere,
                street_info_line1: this.state.street_info_line1,
                street_info_line2: this.state.street_info_line2,
                country_code: this.state.country_code,
                zip_code: this.state.zip_code,
                city: this.state.city,
                province: this.state.province,
                street_number: this.state.street_number,
                lat: this.state.lat,
                long: this.state.long,
                formatted_address: this.state.formatted_address,
                formatted_address: this.state.formatted_address,
                legal_conditions: this.state.legal_conditions
            }
            console.log("PAYLOAD TO SEND", payload);
            return axios.post(
                this.state.signup_server_url+"/rgl_form/update_lead",
                payload,
                { headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                }
            }).then((response)=>{
                    console.log("Response save lead", response);
                    this.setState({step: 40});
                }).catch((error) => {
                    console.log("Error",error);
                    this.setState({step: 20});
                });
        }

    }

    handleChange(e){
        let element_name = e.target.name;
        let element_value = e.target.value;
        let newState = merge({}, this.state);
        let element_is_valid = false;
        
        // console.log(element_value);
        switch(element_name){
            case "regala_code":
                if ((element_value.length == 5) || (element_value.length == 6) ){
                    // && element_value.match(/^[A-Za-z]+$/)
                    element_is_valid = true;
                }
                element_value = element_value.toUpperCase();
                break;
            case "name":
                if (element_value.length > 2){
                    element_is_valid = true;
                }
                break;
            case "last_name":
                if (element_value.length > 2){
                    element_is_valid = true;
                }
                break;
            case "email":
                if (this.isEmail(element_value)){
                    element_is_valid = true;
                }
                break;
            case "phone":
                if (element_value.length > 8){
                    element_is_valid = true;
                }
                break;
            case "legal_conditions":
                element_is_valid = (e.target.checked) ? true : false;
                break;
            case "genere":
                element_is_valid = true;
                break;
            case "birthdate":
                element_is_valid = true;
                break;
            case "address1":
                if (element_value.length > 5){
                    element_is_valid = true;
                }
                break;
            case "address2":
                if (element_value.length > 3){
                    element_is_valid = true;
                }
                break;
            case "dni":
                if(this.validateDNI(element_value)){
                    element_is_valid = true;
                }
                break;
        }
        
        newState[element_name]= element_value;
        newState["validate_elements"][element_name]= true;
        newState["valid_elements"][element_name]=element_is_valid;
        newState.validate_form= false;
        newState.valid_form= false;
        this.setState(newState);
    }
    isEmail(email){
            if (/^[a-zA-Z0-9/+_+-/.]+@[a-zA-Z0-9/+-/.]+\.[A-Za-z]+$/.test(email)){
                return true;
            } else {
                return false;
            }
    }
    
    setBtnActive(amount){
        if ((this.state.amount===amount) && (!this.state.variable_amount)){
            return "btn_amount  fixed active";
        } else {
            return "btn_amount fixed";
        }
    }
    
    elementClassName(element_name){
        let default_classname = (element_name === "legal_conditions") ? "form-check-input" : "form-control"
        if (!this.state.validate_elements[element_name]) return default_classname; 
        if (this.state.valid_elements[element_name]){
            return default_classname + " is-valid";
        } else {
            return default_classname + " is-invalid";
        }
    }
    
    setAddress(address_state){
        this.setState({
            street_info_line1: address_state.street_info_line1, 
            street_info_line2: address_state.street_info_line2,
            current_address: address_state.current_address,
            city: address_state.city,
            country_code: address_state.country_code,
            formatted_address: address_state.formatted_address,
            lat: address_state.lat,
            long: address_state.long,
            province: address_state.province,
            route: address_state.route,
            street_number: address_state.street_number,
            zip_code: address_state.zip_code,
        
        });
        
    }
    
    render(){
        
        return(
            <div>
                <form id="esprint_solidari_formulari">
                    
                    { this.state.step == 0 &&
                        <div>
                            <div className="row justify-content-center">
                                <div className="col-4 align-self-center">
                                    <div className="form-group">
                                        <label htmlFor="variable_amount">Introdueix el teu codi:</label>
                                        <input 
                                        id="regala_code"
                                        type="text"
                                        className={this.elementClassName("regala_code")} 
                                        name="regala_code"
                                        onChange={this.handleChange}
                                        value={this.state.regala_code}
                                        maxLength="6"
                                        />
                                        <div className="invalid-feedback">
                                            Si us plau, introdueix un codi vàlid.
                                        </div>
                                    </div>
                                    <div className="form-group>">
                                        <button 
                                            className="btn btn-danger float-right"
                                            disabled={this.state.form_enabled ? "" : "disabled"}
                                            type="button" 
                                            style={{}} 
                                            onClick={this.saveCode}
                                            >Continuar</button>
                                    </div>
                                </div>
                            
                               
                            </div>
                        </div>
                    }
                    {
                        ((this.state.step > 0) && (this.state.step <40) && (this.state.payment_type!="")) &&
                        <div className="row">
                            <div className="col">
                                { 
                                this.state.payment_type == "annual" &&
                                    <h3 className="text-center" style={{marginBottom: "30px"}}>
                                        El teu regal: <strong>"1 any de soci Òmnium"</strong> - {this.state.regala_code}
                                    </h3>
                                }
                                { 
                                this.state.payment_type == "biannual" &&
                                    <h3 className="text-center"  style={{marginBottom: "30px"}}>
                                        El teu regal: <strong>"6 mesos de soci Òmnium"</strong> - {this.state.regala_code}
                                    </h3>
                                }
                            </div>
                        </div>
                    }
                    
                    <div className="row">
                        <div className="col">
                        { this.state.step == 1 &&
                            <div>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="form_name">Nom *</label>
                                            <input type="text" 
                                                id="form_name" 
                                                name="name" 
                                                className={this.elementClassName("name")} 
                                                onChange={this.handleChange}
                                                required/>
                                            <div className="invalid-feedback">
                                            Si us plau, introdueix un nom vàlid.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="form_last_name">Cognoms *</label>
                                            <input type="text" 
                                                className={this.elementClassName("last_name")} 
                                                id="form_last_name" 
                                                name="last_name" 
                                                onChange={this.handleChange}
                                                required/>
                                            <div className="invalid-feedback">
                                            Si us plau, introdueix un cognom vàlid.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="form_email">Correu electrònic *</label>
                                            <input type="email" 
                                                id="form_email" 
                                                name="email"
                                                className={this.elementClassName("email")} 
                                                onChange={this.handleChange}
                                                required/>
                                            <div className="invalid-feedback">
                                            Si us plau, introdueix un email vàlid.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="form_email">Telèfon *</label>
                                            <input type="phone" 
                                                id="form_phone" 
                                                name="phone"
                                                className={this.elementClassName("phone")} 
                                                onChange={this.handleChange}
                                                required/>
                                            <div className="invalid-feedback">
                                            Si us plau, introdueix un telefon vàlid.
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <div className="form-group form-check">
                                            <input 
                                                type="checkbox" 
                                                id="exampleCheck1"
                                                name="legal_conditions"
                                                className={this.elementClassName("legal_conditions")} 
                                                onChange={this.handleChange}
                                                />
                                            <label className="form-check-label" htmlFor="exampleCheck1">
                                            Accepto les condicions d'ús i la política de privacitat
                                            </label>
                                            <div className="invalid-feedback">
                                                    Per procedir has de llegir les condicions i marcar la casella.
                                            </div>
                                            <div>
                                                <p>
                                                    <strong>Informació bàsica sobre protecció de dades</strong><br/>
                                                    <strong>Responsable</strong>: ÒMNIUM CULTURAL<br/>
                                                    <strong>Finalitat</strong>: Gestionar les peticions formulades, l’enviament de comunicacions comercials i la vinculació dels socis amb l’entitat<br/>
                                                    <strong>Legitimació</strong>: Consentiment de l’interessat<br/>
                                                    <strong>Destinataris</strong>: Les dades es podran comunicar a entitats vinculades a Òmnium Cultural<br/>
                                                    <strong>Drets</strong>: Accés, rectificació, supressió, oposició, limitació i portabilitat de dades<br/>
                                                    <strong>Més informació</strong>: 
                                                        Consulta la &nbsp; 
                                                            <a style={{textDecoration: "underline"}} href="https://www.omnium.cat/ca/politica-privacitat/" target="_blank">
                                                                Política de privacitat
                                                            </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group>">
                                            <button 
                                                className="btn btn-danger float-right"
                                                disabled={this.state.form_enabled ? "" : "disabled"}
                                                type="button" 
                                                style={{}} 
                                                onClick={this.saveLead}
                                                >Continuar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        
                        { this.state.step == 2 &&
                            <div>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="form_birthdate">Data de naixement</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-4">
                                        <div className="form-group">
                                            <label htmlFor="">Dia</label>
                                            <select 
                                                name="dia" 
                                                value={this.state.dia}
                                                className={this.elementClassName("dia")} 
                                                onChange={this.birthdateChange}
                                                required>
                                                <option value="0">Escull dia</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                                <option value="13">13</option>
                                                <option value="14">14</option>
                                                <option value="15">15</option>
                                                <option value="16">16</option>
                                                <option value="17">17</option>
                                                <option value="18">18</option>
                                                <option value="19">19</option>
                                                <option value="20">20</option>
                                                <option value="21">21</option>
                                                <option value="22">22</option>
                                                <option value="23">23</option>
                                                <option value="24">24</option>
                                                <option value="25">25</option>
                                                <option value="26">26</option>
                                                <option value="27">27</option>
                                                <option value="28">28</option>
                                                <option value="29">29</option>
                                                <option value="30">30</option>
                                                <option value="31">31</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-group">
                                            <label htmlFor="">Mes</label>
                                            <select 
                                                name="mes" 
                                                value={this.state.mes}
                                                className={this.elementClassName("mes")} 
                                                onChange={this.birthdateChange}
                                                required>
                                                <option value="0">Escull mes</option>
                                                <option value="1">Gener</option>
                                                <option value="2">Febrer</option>
                                                <option value="3">Març</option>
                                                <option value="4">Abril</option>
                                                <option value="5">Maig</option>
                                                <option value="6">Juny</option>
                                                <option value="7">Juliol</option>
                                                <option value="8">Agost</option>
                                                <option value="9">Setembre</option>
                                                <option value="10">Octubre</option>
                                                <option value="11">Novembre</option>
                                                <option value="12">Desembre</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-group">
                                            <label htmlFor="">Any</label>
                                            <select 
                                            value={this.state.any}
                                            name="any"
                                            className={this.elementClassName("any")} 
                                            onChange={this.birthdateChange}
                                            required>
                                            <option value="1920">1920</option>
                                            <option value="1921">1921</option>
                                            <option value="1922">1922</option>
                                            <option value="1923">1923</option>
                                            <option value="1924">1924</option>
                                            <option value="1925">1925</option>
                                            <option value="1926">1926</option>
                                            <option value="1927">1927</option>
                                            <option value="1928">1928</option>
                                            <option value="1929">1929</option>
                                            <option value="1930">1930</option>
                                            <option value="1931">1931</option>
                                            <option value="1932">1932</option>
                                            <option value="1933">1933</option>
                                            <option value="1934">1934</option>
                                            <option value="1935">1935</option>
                                            <option value="1936">1936</option>
                                            <option value="1937">1937</option>
                                            <option value="1938">1938</option>
                                            <option value="1939">1939</option>
                                            <option value="1940">1940</option>
                                            <option value="1941">1941</option>
                                            <option value="1942">1942</option>
                                            <option value="1943">1943</option>
                                            <option value="1944">1944</option>
                                            <option value="1945">1945</option>
                                            <option value="1946">1946</option>
                                            <option value="1947">1947</option>
                                            <option value="1948">1948</option>
                                            <option value="1949">1949</option>
                                            <option value="1950">1950</option>
                                            <option value="1951">1951</option>
                                            <option value="1952">1952</option>
                                            <option value="1953">1953</option>
                                            <option value="1954">1954</option>
                                            <option value="1955">1955</option>
                                            <option value="1956">1956</option>
                                            <option value="1957">1957</option>
                                            <option value="1958">1958</option>
                                            <option value="1959">1959</option>
                                            <option value="1960">1960</option>
                                            <option value="1961">1961</option>
                                            <option value="1962">1962</option>
                                            <option value="1963">1963</option>
                                            <option value="1964">1964</option>
                                            <option value="1965">1965</option>
                                            <option value="1966">1966</option>
                                            <option value="1967">1967</option>
                                            <option value="1968">1968</option>
                                            <option value="1969">1969</option>
                                            <option value="1970">1970</option>
                                            <option value="1971">1971</option>
                                            <option value="1972">1972</option>
                                            <option value="1973">1973</option>
                                            <option value="1974">1974</option>
                                            <option value="1975">1975</option>
                                            <option value="1976">1976</option>
                                            <option value="1977">1977</option>
                                            <option value="1978">1978</option>
                                            <option value="1979">1979</option>
                                            <option value="1980">1980</option>
                                            <option value="1981">1981</option>
                                            <option value="1982">1982</option>
                                            <option value="1983">1983</option>
                                            <option value="1984">1984</option>
                                            <option value="1985">1985</option>
                                            <option value="1986">1986</option>
                                            <option value="1987">1987</option>
                                            <option value="1988">1988</option>
                                            <option value="1989">1989</option>
                                            <option value="0">Escull any</option>
                                            <option value="1990">1990</option>
                                            <option value="1991">1991</option>
                                            <option value="1992">1992</option>
                                            <option value="1993">1993</option>
                                            <option value="1994">1994</option>
                                            <option value="1995">1995</option>
                                            <option value="1996">1996</option>
                                            <option value="1997">1997</option>
                                            <option value="1998">1998</option>
                                            <option value="1999">1999</option>
                                            <option value="2000">2000</option>
                                            <option value="2001">2001</option>
                                            <option value="2002">2002</option>
                                            <option value="2003">2003</option>
                                            <option value="2004">2004</option>
                                            <option value="2005">2005</option>
                                            <option value="2006">2006</option>
                                            <option value="2007">2007</option>
                                            <option value="2008">2008</option>
                                            <option value="2009">2009</option>
                                            <option value="2010">2010</option>
                                            <option value="2011">2011</option>
                                            <option value="2012">2012</option>
                                            <option value="2013">2013</option>
                                            <option value="2014">2014</option>
                                            <option value="2015">2015</option>
                                            <option value="2016">2016</option>
                                            <option value="2017">2017</option>
                                            <option value="2018">2018</option>
                                            <option value="2019">2019</option>
                                            <option value="2020">2020</option>
                                        </select>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="form_genere">Gènere</label>
                                            <select
                                                id="form_genere" 
                                                name="genere" 
                                                className={this.elementClassName("genere")} 
                                                onChange={this.handleChange}
                                                required>
                                                    <option>-</option>
                                                    <option>dona</option>
                                                    <option>home</option>
                                                    <option>altre</option>
                                            </select>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <SearchAddress name={this.state.name + " "+this.state.last_name} setAddress={this.setAddress}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="form_dni">DNI</label>
                                            <input type="text" 
                                                id="form_dni" 
                                                name="dni" 
                                                className={this.elementClassName("dni")} 
                                                onChange={this.handleChange}
                                                required/>
                                            <div className="invalid-feedback">
                                            Si us plau, introdueix un DNI vàlid
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group>">
                                            <button 
                                                className="btn btn-danger float-right"
                                                disabled={this.state.form_enabled ? "" : "disabled"}
                                                type="button" 
                                                style={{}} 
                                                onClick={this.saveAll}
                                                >Continuar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>                        
                        }                   
                        
                        </div>
                    </div>
                    { this.state.step == 10 &&
                        <div style={{height: "350px"}}>
                            <h2 className="text-center">Carregant dades...</h2>
                        </div>
                    }   
                    { this.state.step == 20 && // uh oh problems!
                        <div style={{height: "350px"}}>
                            <h4 className="text-center">Hi ha hagut un problema en el procés. Intenteu-ho més tard, si us plau, o envieu un email a botiga@omnium.cat</h4>
                        </div>
                    }   
                    { this.state.step == 30 && // uh oh code not found
                        <div style={{height: "350px"}}>
                            <h4 className="text-center">No s'ha pogut trobar un regal corresponent al codi introduït. <br/>Intenteu-ho de nou, o envieu un email a botiga@omnium.cat</h4>
                        </div>
                    }   
                    { this.state.step == 40 && // THANK YOU
                        <div style={{height: "350px", marginTop: "60px"}}>
                            <h2 className="text-center">Gràcies!<br/>Ja has finalitzat el procés de registre.<br/> En breu rebràs un correu per informar-te.</h2>
                        </div>
                    }   
                    
                </form>
            </div>
        )
    }
}

export default RegalaForm;