import React from 'react';
import Script from 'react-load-script';
import * as countries from "./utils/countries.js"

// import ShowAddress from "./ShowAddress.jsx"
export default class SearchAddress extends React.Component {
    constructor(props) {
        super();
        this.state = {
            name: props.name,
            street_info_line1: "",
            street_info_line2: "",
            route: "",
            street_number: "",
            city: "",
            bounds: null,
            current_address: "",
            automatic_address: true,
            is_valid: false,
            placeholder: "",
            current_place_id: "",
            zip_code: "",
            results_zero: true,
            enabled: false,
            show_predictions: null,
            predictions: null,
            country_code: "ca",
        };
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.setAddress = props.setAddress.bind(this);
        this.confirmAddress = this.confirmAddress.bind(this);
    }
    handleKeyPress= (event) => {
        event.preventDefault();
        let key = event.key || event.keyCode;
        
        if((key !== "ArrowDown") && (key !== "ArrowUp")){
            return;
        }
        if(event.target.className.indexOf("address_auto")> -1){
            if(key === "ArrowDown"){
                let predictions = document.querySelectorAll(".address_prediction");
                if (predictions.length > 0){
                    predictions[0].focus();
                }            
            }
        }
        if(event.target.className.indexOf("address_prediction")> -1){
            if(key === "ArrowDown"){
                let predictions = document.querySelectorAll(".address_prediction");
                for (var i = 0; i < (predictions.length-1); i++){
                    if (predictions[i] === event.target){
                        if(i!==predictions.length){
                            predictions[i+1].focus();
                        }
                    }
                }
            }
            if(key === "ArrowUp"){
                let predictions = document.querySelectorAll(".address_prediction");
                for (i = (predictions.length-1); i > -1; i--){
                    if (predictions[i] === event.target){
                        if(i!==0){
                            predictions[i-1].focus();
                        }
                    }
                }
            }
        }
        return;
    }

    confirmAddress(confirm){
        // console.log("Confirm:", confirm);
        this.setState({is_valid: confirm});
        this.setAddress(this.state);
    }

    handleScriptLoad = () => {
        /*global google*/ // To disable any eslint 'google not defined' errors
        
        this.setState({bounds: new google.maps.LatLngBounds(
                new google.maps.LatLng(43.113935, 0.263858),
                new google.maps.LatLng(40.559832, 3.032669))
        });
        this.session_token = new google.maps.places.AutocompleteSessionToken();
        this.autocomplete = new google.maps.places.AutocompleteService();
    }

    address_on_change = (e) => {
        // console.log("on change", e.target.value)

        // e.target.setAttribute("data-internal-is-valid",false);
        this.setState({current_address: e.target.value})
        this.setState({address_validates: false})
        this.setState({route_validates: false})
        let new_state = {};
        if ( (e.target.value.indexOf(" ")>0) || (e.target.value.length>6)){
            new_state.enabled = true;
            new_state.current_place_id = null;
            new_state.results_zero = false;
          } else {
            new_state.show_predictions = false;
            new_state.results_zero = false;
          }
          this.setState(new_state);
        if ( (e.target.value.indexOf(" ")>0) || (e.target.value.length>6)){
            this.get_predictions(e.target.value);
        }
    }

    address_second_line_on_change = (e) => {
        console.log("segona linia updating", e.target.value);
        this.setState({street_info_line2: e.target.value}); 
        this.evaluate_address_is_ok();
    }

    get_predictions = (search_input) => {
        this.autocomplete.getPlacePredictions(
            {
                input: search_input, 
                sessionToken: this.session_token,
                fields: ['basic'],
                bounds: this.state.bounds,
                types: ['address']
            },
                this.predictions_received
            );
    }

    predictions_received  = (predictions, status) => { 
        
        if(status === "ZERO_RESULTS"){
            this.setState({show_predictions:false});
            this.setState({results_zero:true});
            this.setState({predictions:[]});
        } else {
            this.setState({predictions:predictions});
            this.setState({show_predictions:true});
            this.setState({results_zero:false});
        }
    }

    get_place = (place_id) => {
        let service = new google.maps.places.PlacesService(document.getElementById("_map"))
        service.getDetails(
            {
                placeId: place_id,
                fields: ['address_components', 'formatted_address', 'geometry']
            }
            , (place, status) => {
                console.log("Place:", place)
                this.setState({current_place_id: place_id});
                this.setState({predictions: []});
                this.setState({results_zero: false});
                this.setState({show_predictions: false});
                let complete_address = {
                    "street_number": null, 
                    "route": null, 
                    "locality": null, 
                    "administrative_area_level_2": null, 
                    "administrative_area_level_1": null,
                    "country": null,
                    "postal_code": null
                }

                // we need route, street number, city, zip and country
                place.address_components.forEach(function(component){
                    if((component["types"][0]==="country") || component["types"][0]==="administrative_area_level_1"){
                        complete_address[component["types"][0]]= component["short_name"]
                    } else {
                        complete_address[component["types"][0]]= component["long_name"]
                    }
                });
                // update values of country. street + Number, zip code, city, street number
                if(complete_address["administrative_area_level_1"]==="CT"){
                    this.setState({country_code: "cat"});
                    this.setState({formatted_address: place.formatted_address.replace("Espanya","Catalunya")});
                } else {
                    this.setState({country_code: complete_address["country"].toLowerCase()});
                    this.setState({formatted_address: place.formatted_address});
                }
                if(complete_address["street_number"] != null){
                    this.setState({street_number: complete_address["street_number"]});
                } else {
                    this.setState({street_number: ""});
                }
                this.setState({city: complete_address["locality"] === null ? "": complete_address["locality"]});
                this.setState({province: complete_address["administrative_area_level_2"]});
                if(complete_address["postal_code"] != null){
                    this.setState({zip_code: complete_address["postal_code"]});
                } else {
                    this.setState({zip_code: ""});
                }
                if(place.geometry){
                    this.setState({lat: place.geometry.location.lat()});
                    this.setState({long: place.geometry.location.lng()});
                } else {
                    this.setState({lat: ""});
                    this.setState({long: ""});
                }
                this.setState({route: this.abrevAddress(complete_address["route"])});
                this.evaluate_address_is_ok();
                document.getElementById("_address_street_info_line2").focus(); 
            })
    }
   
    abrevAddress = (original_string) => {
        let dictionary =  {
            "Passeig": "Pg.",
            "Carrer": "C.",
            "Carretera": "Ctra." ,
            "Avinguda": "Av.",
            "Passatge": "Ptge.",
            "Plaça": "Pl.",
            "Rambla": "Rbla.",
            "Ronda": "Rda.",
            "Travessera": "Trav.",
            "Travessia": "Trv.",
            "Urbanització": "Urb.",
            "Calle": "C.",
            "Camino": "C.º",
            "Paseo": "P.º",
            "Avenida": "Av.",
            "Pasaje": "Pje.",
            "Plaza": "Pl.",
            "Travesera": "Trav.",
            "Travesía": "Tr.ª",
            "Urbanización": "Urb.",
            "Street": "St.",
            "Avenue": "Ave",
            "Boulevard": "Blvd",
            "Center": "Ctr",
            "Highway": "Hwy",
            "Road": "Rd",
            "Square": "Sq"
        };
        let transformed_string = original_string;
        for (let abrev in dictionary) {
            // console.log("Replace:", dictionary[abrev], abrev)
            transformed_string = transformed_string.replace(abrev, dictionary[abrev]);
        }
        // console.log("Abrev: ", transformed_string);
        return transformed_string;
    }
    
    evaluate_address_is_ok = () => {
        
        // we need postal code, and check if street number is missed
        if(  
            (this.state.zip_code !== "") 
        ) {
            this.setState({route_validates: true});
        } else {
            this.setState({route_validates: false});
        }

        if(  
            (this.state.zip_code !== "") &&  
            (this.state.street_info_line2 !== "") &&
            (this.state.city !== "")
        ){
            this.setState({address_validates: true});
        } else {
            this.setState({address_validates: false});
        }

        if(this.state.city===""){
            this.setState({route_validates: false});
        }
        this.build_address();
    }

    build_address = () => {
        let proposed_address = this.state.route;
        //street number
        if(this.state.street_number !==""){
            proposed_address +=  " " + this.state.street_number;
        } 
        if(!this.state.city === ""){
            proposed_address += ", " + this.state.city;
            }
        this.setState({street_info_line1: proposed_address.replace(",","")}); 
        this.setState({current_address: proposed_address});
    }

    switch_to_automatic = (go_automatic) => {
        this.setState({automatic_address: go_automatic});
   }
    render = () => {
        return(
            <div  style={{  marginTop: "15px", marginBottom: "15px"}}>
                <Script
                    // url={"https://maps.googleapis.com/maps/api/js?key=AIzaSyCTm3Q_72GwUzhesPeqtNm4Sr7sLGthFok&libraries=places&language="+this.props.locale}
                    url={"https://maps.googleapis.com/maps/api/js?key=AIzaSyBK3JCtfSBHT1gJcBMmCwtgeziOTbE7B-4&libraries=places&language="+this.props.locale}
                    onLoad={this.handleScriptLoad}
                />
                <p>
                    <strong>
                        Adreça postal:
                    </strong>
                </p>
                {
                    this.state.automatic_address &&
                    (!this.state.is_valid) &&
                    this.show_automatic_controls()
                }
                {/* {
                    !this.state.automatic_address &&
                    (!this.state.is_valid) &&
                    this.show_manual_controls()
                } */}
                {  
                    <div
                        id={"_formatted_address"}
                        >
                            {
                                this.state.route_validates  &&
                                <div style={{margin: "15px"}} className="address_box">
                                    <p>
                                        {this.state.name}
                                        <br/>
                                        {this.state.route} {this.state.street_number} {this.state.street_info_line2} 
                                        <br/>
                                        {this.state.zip_code} {this.state.city} 
                                        <br/>
                                        { ((this.state.country_code !=="cat") && (this.state.country_code !=="es")) &&
                                        countries.get_country_name(this.state.country_code)
                                        }
                                    </p>
                                </div>
                            }
                            {
                                ((this.state.street_number=== null || this.state.street_number=== "")) &&
                                this.state.address_validates &&
                                <div className="alert alert-warning">
                                    <small id={"_formatted_address_help"} className="form-text text-muted">
                                        La teva adreça no sembla tenir numero de carrer.
                                    </small>
                                </div>
                            }
                           { 
                            this.state.address_validates  &&
                                <div className="form-check">
                                    <input className="form-check-input step1"
                                    type="checkbox"
                                    value="1"
                                    id={"_address_is_ok"}
                                    data-validation-id={"_address_is_valid"}
                                    checked = { this.state.is_valid ? "checked" : "" }
                                    onChange={e =>{
                                        console.log("Is valid:",e.target.checked);
                                        this.confirmAddress(e.target.checked);
                                        }}
                                    required  />
                                    
                                    <label className="form-check-label" htmlFor="conditions_check">
                                        Confirmo que aquesta és la meva adreça
                                    </label>
                                    <div className="invalid-feedback">
                                        És necessari que confirmis la teva adreça.
                                    </div>
                                </div>
                            }
                    </div>
                }
            </div>
        )
    }
    invalid_flag = () =>{
        return "is-invalid";
    }
    show_automatic_controls = () => {
        return(
                    <div> 
                        <div className="form-row">
                            <div className="col-12">
                                    <div 
                                        className="form-group"
                                    >
                                        <label>Carrer i número, ciutat<br/><span style={{fontSize: "0.8em", color: "#444"}}>
                                            Per exemple: Carrer Diputació 276
                                            </span></label>
                                        <input
                                            id={"_address_street_info_line1"}
                                            placeholder={this.state.placeholder}
                                            value={this.state.current_address}
                                            type="text"
                                            className={"address_auto form-control " + (this.state.route_validates? "is-valid" : this.invalid_flag())}
                                            name={"_address_street_info_line1"}
                                            data-validation-id={"_address"}
                                            onChange={e =>{
                                                console.log("Address change");
                                                this.address_on_change(e)
                                            }}
                                            required
                                        />
                                        <ul style={{marginTop: "15px"}}>
                                            {
                                                (this.state.current_place_id!=null) && 
                                                this.state.enabled && 
                                                (this.state.street_number==="") &&
                                                <li className="small text-danger">
                                                    L'adreça no te número de carrer
                                                </li>
                                            }
                                            {
                                                (this.state.current_place_id!=null) && 
                                                this.state.enabled && 
                                                (this.state.zip_code==="") &&
                                                <li  className="small text-danger">
                                                    No es pot trobar un codi postal per a aquesta adreça
                                                </li>
                                            }
                                            {
                                                (this.state.current_place_id!=null) &&
                                                (this.state.city==="") && 
                                                this.state.enabled &&
                                                <li  className="small text-danger">
                                                    No s'ha identificat la localitat
                                                </li>
                                            }
                                            </ul>
                                            {
                                                (
                                                    this.state.current_place_id!=null) && 
                                                    this.state.enabled && 
                                                    (
                                                        (this.state.zip_code==="") || 
                                                        (this.state.street_number==="") ||
                                                        (this.state.city==="")
                                                    ) &&
                                                <div className="small float-right">
                                                    {
                                                        this.show_button_to_manual()
                                                    }
                                                </div>
                                            }
                                            
                                            
                                            {
                                                this.state.results_zero && this.state.enabled &&
                                                <div className="small float-right">
                                                    Sense resultats.&nbsp;
                                                    {
                                                        this.show_button_to_manual()
                                                    }
                                                </div>
                                                
                                            }
                                        
                                        {  this.state.show_predictions && this.state.enabled && (this.state.predictions !== null) &&
                                            <div style={{padding: "0 15px"}}>
                                                <p className="small">
                                                    Coincidències:
                                                    </p>
                                                <div className="list-group">
                                                    {
                                                        this.state.predictions.map(
                                                            (prediction) => {
                                                                return(
                                                                    <button 
                                                                        type="button"
                                                                        key={prediction.place_id} 
                                                                        id={prediction.place_id}
                                                                        
                                                                        className="list-group-item list-group-item-action address_prediction" 
                                                                        style={{
                                                                            borderRadius: "0",
                                                                            backgroundColor: "#fff",
                                                                            border: "1px solid rgba(0,0,0,.125)",
                                                                            color: "#495057",
                                                                            fontSize: "0.75rem",
                                                                            lineHeight: "1",
                                                                            cursor: "Pointer"
                                                                        }}
                                                                        onClick={e =>{
                                                                            this.get_place(e.target.id)
                                                                        }}
                                                                    >{prediction.description.replace(", Espanya", "")}</button>
                                                                )
                                                            }
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        }
                                        <div className="invalid-feedback">
                                            {/* L'adreça és un camp obligatori */}
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div className="form-row">
                        <div className="col-12">
                            <div className="form-group">
                                <label>
                                    Pis, porta, casa...
                                    <br/><span style={{fontSize: "0.8em", color: "#444"}}>
                                        Per exemple '3r 1a', 'Casa', 'Can Mata'
                                        </span>
                                </label>
                                <input type="text"
                                    id={"_address_street_info_line2"}
                                    className={" form-control "}
                                    name="street_info_line2"
                                    value={this.state.street_info_line2}
                                    data-validation-id={"_address_street_info_line2"}
                                    onChange={e =>{
                                        // this.setState({street_info_line2: e.target.value});
                                        this.address_second_line_on_change(e);

                                    }}
                                    required
                                    />
                                <div className="invalid-feedback">
                                    Cal que aquest camp sigui més llarg
                                </div>
                            </div>
                        </div>
                        <div id={"_map"}>
                            <p></p>
                        </div>
                    </div>
                    </div>
                
        )
    }
    
    show_manual_controls = () => {
        return(
            <div >
                <div className="form-row">
                    <div className="col-4">
                        <div className="form-group">
                            <label>pais</label>
                            <select
                                className="form-control"
                                value={this.state.country_code}
                                data-validation-id={"_address_country_code"}
                                onChange={e =>{
                                    this.setState({country_code: e.target.value})
                                }}
                                required
                            >
                            {
                                countries.Countries.map(
                                (country) => {
                                    return(
                                    <option key={country[0]} value={country[0]}>{country[1]}</option>
                                    )
                                }
                                )
                            }
                            </select>
                        </div>
                    </div>
                    <div className="col-8">
                        <div className="form-group">
                        <label>poblacio')}</label>
                        <input type="text"
                            className={"form-control " + this.props.step}
                            name="city"
                            value={this.state.city}
                            data-validation-id={"_address_city"}
                            onChange={e =>{
                            utilsForm.validate(this.props, e.target);
                            }}
                            required
                            />
                        <div className="invalid-feedback">
                            'cal_escriure_poblacio'
                        </div>
                        </div>
                    </div>
                </div>    
                <div className="form-row">
                    <div className="col-12">
                        <div className="form-group">
                            <label>'search_address_route</label>
                            <input
                                type="text"
                                className={"form-control " + this.props.step}
                                name="street_info_line1"
                                data-validation-id={"_address_street_info_line1"}
                                value={this.state.street_info_line1}
                                onChange={e =>{
                                    utilsForm.validate(this.props, e.target);
                                }}
                                required
                            />
                            <div className="invalid-feedback">
                                'cal_escriure_adreca
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-8">
                        <div className="form-group">
                            <label>Pis i porta</label>
                            <input
                                type="text"
                                className={"form-control " + this.props.step}
                                name="street_info_line2"
                                data-validation-id={"_address_street_info_line2"}
                                value={this.state.street_info_line2}
                                onChange={e =>{
                                    utilsForm.validate(this.props, e.target);
                                }}
                                required
                            />
                            <div className="invalid-feedback">
                                'cal_que_sigui_mes_llarg
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="form-group">
                        <label>'codi_postal</label>
                        <input
                            type="text"
                            className={"form-control " + this.props.step}
                            name="zip_code"
                            value={this.state.zip_code}
                            data-validation-id={ ((this.state.country_code === "cat")
                            || (this.props.main_contact.address.country_code === "es")) ? "_address_zip_code" : "_txt_address_zip_code"}
                            // data-validation-id={"_address_zip_code"}
                            onChange={e =>{
                                utilsForm.validate(this.props, e.target);
                            }}
                            required
                        />
                        <div className="invalid-feedback">
                            'cal_escriure_codi
                        </div>
                        </div>
                    </div>
                
                
                </div>
                <div className="float-right">
                    <button type="button" className="btn btn-sm btn-outline-primary"
                        onClick={e=>{
                            this.switch_to_automatic(true);
                        }}
                        style={{
                            fontSize: "0.75rem",
                            backgroundColor: "#fff",
                            color: "#ef3e1e",
                            padding: "10px",
                            borderRadius: "5px",
                            borderColor: "#888"
                        }}
                        >
                            'search_address_choose_auto
                    </button>
                </div>
                <div className="clearfix"></div>
            </div>
        )
    }
    show_button_to_manual = () => {
        return(
            <button type="button" className="btn btn-sm btn-outline-primary"
                onClick={e=>{
                    this.switch_to_automatic(false);
                }}
                style={{
                    fontSize: "0.75rem",
                    backgroundColor: "#fff",
                    color: "#ef3e1e",
                    padding: "10px",
                    borderRadius: "5px",
                    borderColor: "#888"
                }}
            >
                'search_address_choose_manual
            </button>
        )
    }
}

