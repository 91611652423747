/* eslint-disable import/prefer-default-export */
export const Countries = [
    ["cat", "Catalunya"],
    ["es", "Resta de l'estat"],
    ["af", "Afganistan"],
    ["ax", "Aland"],
    ["al", "Albània"],
    ["de", "Alemanya"],
    ["dz", "Algèria"],
    ["ad", "Andorra"],
    ["ao", "Angola"],
    ["ai", "Anguilla"],
    ["aq", "Antàrtida"],
    ["ag", "Antigua i Barbuda"],
    ["sa", "Aràbia Saudí"],
    ["ar", "Argentina"],
    ["am", "Armènia"],
    ["aw", "Aruba"],
    ["au", "Austràlia"],
    ["at", "Àustria"],
    ["az", "Azerbaidjan"],
    ["bs", "Bahames, les"],
    ["bh", "Bahrain"],
    ["bd", "Bangladesh"],
    ["bb", "Barbados"],
    ["by", "Belarús"],
    ["be", "Bèlgica"],
    ["bz", "Belize"],
    ["bj", "Benín"],
    ["bm", "Bermudes"],
    ["bt", "Bhutan"],
    ["bo", "Bolívia"],
    ["bq", "Bonaire, Sint Eustatius i Saba"],
    ["ba", "Bòsnia i Hercegovina"],
    ["bw", "Botswana"],
    ["br", "Brasil"],
    ["bn", "Brunei"],
    ["bg", "Bulgària"],
    ["bf", "Burkina Faso"],
    ["bi", "Burundi"],
    ["kh", "Cambodja"],
    ["cm", "Camerun"],
    ["ca", "Canadà"],
    ["cv", "Cap Verd"],
    ["co", "Colòmbia"],
    ["km", "Comores, les"],
    ["cg", "Congo"],
    ["kp", "Corea del Nord"],
    ["kr", "Corea del Sud"],
    ["ci", "Costa d'Ivori"],
    ["cr", "Costa Rica"],
    ["hr", "Croàcia"],
    ["cu", "Cuba"],
    ["cw", "Curaçao"],
    ["dk", "Dinamarca"],
    ["dj", "Djibouti"],
    ["dm", "Dominica"],
    ["eg", "Egipte"],
    ["sv", "El Salvador"],
    ["ae", "Emirats Àrabs Units, els"],
    ["ec", "Equador"],
    ["er", "Eritrea"],
    ["sk", "Eslovàquia"],
    ["si", "Eslovènia"],
    ["us", "Estats Units, els"],
    ["ee", "Estònia"],
    ["et", "Etiòpia"],
    ["fo", "Fèroe"],
    ["fj", "Fiji"],
    ["ph", "Filipines"],
    ["fi", "Finlàndia"],
    ["fr", "França"],
    ["ga", "Gabon"],
    ["gm", "Gàmbia"],
    ["ge", "Geòrgia"],
    ["gh", "Ghana"],
    ["gi", "Gibraltar"],
    ["gr", "Grècia"],
    ["gd", "Grenada"],
    ["gl", "Groenlàndia"],
    ["gp", "Guadalupe"],
    ["gf", "Guaiana Francesa"],
    ["gu", "Guam"],
    ["gt", "Guatemala"],
    ["gg", "Guernsey"],
    ["gn", "Guinea"],
    ["gq", "Guinea Equatorial"],
    ["gw", "Guinea-Bissau"],
    ["gy", "Guyana"],
    ["ht", "Haití"],
    ["hn", "Hondures"],
    ["hk", "Hong Kong, Xina"],
    ["hu", "Hongria"],
    ["ye", "Iemen, el"],
    ["bv", "Illa Bouvet"],
    ["cx", "Illa Christmas"],
    ["im", "Illa de Man"],
    ["nf", "Illa Norfolk"],
    ["ky", "Illes Caiman"],
    ["cc", "Illes Cocos (Keeling)"],
    ["ck", "Illes Cook"],
    ["fk", "Illes Falkland (Malvines)"],
    ["gs", "Illes Geòrgia del Sud i Sandwich del Sud"],
    ["hm", "Illes Heard i McDonald"],
    ["mp", "Illes Marianes del Nord"],
    ["mh", "Illes Marshall"],
    ["um", "Illes Menors Allunyades dels Estats Units"],
    ["sb", "Illes Salomó"],
    ["tc", "Illes Turks i Caicos"],
    ["vg", "Illes Verges Britàniques"],
    ["vi", "Illes Verges dels Estats Units"],
    ["in", "Índia"],
    ["id", "Indonèsia"],
    ["ir", "Iran"],
    ["iq", "Iraq, l'"],
    ["ie", "Irlanda"],
    ["is", "Islàndia"],
    ["il", "Israel"],
    ["it", "Itàlia"],
    ["jm", "Jamaica"],
    ["jp", "Japó"],
    ["je", "Jersey"],
    ["jo", "Jordània"],
    ["kz", "Kazakhstan"],
    ["ke", "Kenya"],
    ["kg", "Kirguizstan"],
    ["ki", "Kiribati"],
    ["kw", "Kuwait"],
    ["la", "Lao"],
    ["ls", "Lesotho"],
    ["lv", "Letònia"],
    ["lb", "Líban"],
    ["lr", "Libèria"],
    ["ly", "Líbia"],
    ["li", "Liechtenstein"],
    ["lt", "Lituània"],
    ["lu", "Luxemburg"],
    ["mo", "Macao, Xina"],
    ["mk", "Macedònia"],
    ["mg", "Madagascar"],
    ["my", "Malàisia"],
    ["mw", "Malawi"],
    ["mv", "Maldives"],
    ["ml", "Mali"],
    ["mt", "Malta"],
    ["ma", "Marroc, el"],
    ["mq", "Martinica"],
    ["mu", "Maurici"],
    ["mr", "Mauritània"],
    ["yt", "Mayotte"],
    ["mx", "Mèxic"],
    ["fm", "Micronèsia"],
    ["mz", "Moçambic"],
    ["md", "Moldàvia"],
    ["mc", "Mònaco"],
    ["mn", "Mongòlia"],
    ["me", "Montenegro"],
    ["ms", "Montserrat"],
    ["mm", "Myanmar"],
    ["na", "Namíbia"],
    ["nr", "Nauru"],
    ["np", "Nepal"],
    ["ni", "Nicaragua"],
    ["ne", "Níger"],
    ["ng", "Nigèria"],
    ["nu", "Niue"],
    ["no", "Noruega"],
    ["nc", "Nova Caledònia"],
    ["nz", "Nova Zelanda"],
    ["om", "Oman"],
    ["nl", "Països Baixos"],
    ["pk", "Pakistan"],
    ["pw", "Palau"],
    ["ps", "Palestina"],
    ["pa", "Panamà"],
    ["pg", "Papua Nova Guinea"],
    ["py", "Paraguai"],
    ["pe", "Perú"],
    ["pn", "Pitcairn"],
    ["pf", "Polinèsia Francesa"],
    ["pl", "Polònia"],
    ["pt", "Portugal"],
    ["pr", "Puerto Rico"],
    ["qa", "Qatar"],
    ["gb", "Regne Unit"],
    ["cf", "República Centreafricana"],
    ["cd", "República Democràtica del Congo"],
    ["do", "República Dominicana"],
    ["re", "Reunió, la"],
    ["ro", "Romania"],
    ["rw", "Ruanda"],
    ["ru", "Rússia"],
    ["eh", "Sàhara Occidental"],
    ["sh", "Saint Helena, Ascenció i Tristan da Cunha"],
    ["kn", "Saint Kitts i Nevis"],
    ["lc", "Saint Lucia"],
    ["vc", "Saint Vincent i les Grenadines"],
    ["bl", "Saint-Barthélemy"],
    ["mf", "Saint-Martin"],
    ["pm", "Saint-Pierre-et-Miquelon"],
    ["ws", "Samoa"],
    ["as", "Samoa Americana"],
    ["sm", "San Marino"],
    ["va", "Santa Seu"],
    ["st", "São Tomé i Príncipe"],
    ["sn", "Senegal"],
    ["rs", "Sèrbia"],
    ["sc", "Seychelles"],
    ["sl", "Sierra Leone"],
    ["sg", "Singapur"],
    ["sx", "Sint Maarten"],
    ["sy", "Síria"],
    ["so", "Somàlia"],
    ["lk", "Sri Lanka"],
    ["za", "Sud-àfrica"],
    ["ss", "Sudan del Sud, el"],
    ["sd", "Sudan, el"],
    ["se", "Suècia"],
    ["ch", "Suïssa"],
    ["sr", "Surinam"],
    ["sj", "Svalbard i Jan Mayen"],
    ["sz", "Swaziland"],
    ["tj", "Tadjikistan"],
    ["th", "Tailàndia"],
    ["tw", "Taiwan"],
    ["tz", "Tanzània"],
    ["tf", "Terres Australs i Antàrtiques Franceses"],
    ["io", "Territori Britànic de l'Oceà Índic"],
    ["tl", "Timor-Leste"],
    ["tg", "Togo"],
    ["tk", "Tokelau"],
    ["to", "Tonga"],
    ["tt", "Trinidad i Tobago"],
    ["tn", "Tunísia"],
    ["tm", "Turkmenistan"],
    ["tr", "Turquia"],
    ["tv", "Tuvalu"],
    ["td", "Txad"],
    ["cz", "Txèquia"],
    ["ua", "Ucraïna"],
    ["ug", "Uganda"],
    ["uy", "Uruguai"],
    ["uz", "Uzbekistan"],
    ["vu", "Vanuatu"],
    ["ve", "Veneçuela"],
    ["vn", "Vietnam"],
    ["wf", "Wallis i Futuna"],
    ["cl", "Xile"],
    ["cn", "Xina"],
    ["cy", "Xipre"],
    ["zm", "Zàmbia"],
    ["zw", "Zimbàbue"]
  ];

  export const get_country_name = (country_code) => {
      for(let country of Countries){
          if(country[0]=== country_code){
              return country[1];
          }        
      }
      return ""
  }
